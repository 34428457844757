import React from "react";
import {
    Container, Box, Divider, Typography, Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import "../index.css";
import Breadcrumb from "../components/layout/Breadcrumb";
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const breadcrumbData = ["主頁", "會員", "會員名冊"];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#c9bea8',
        color: '#000',
        fontSize: 18,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(memberId, companyName) {
    return { memberId, companyName };
  }
  
  const rows = [
    createData('GM00001', '東淦工程有限公司'),
    createData('GM00002', '展域亞洲有限公司'),
    createData('GM00003',	'保鋼工程有限公司'),
    createData('GM00004',	'煒浩工程有限公司'),
    createData('GM00005',	'鍏歷馬建築管理有限公司'),
    createData('GM00006',	'利生建築工程有限公司'),
    createData('GM00007',	'香港煌商佑鋼鐵實業有限公司'),
    createData('GM00008',	'豐業工程有限公司'),
    createData('GM00009',	'Steel-Clad Engineering Co., Ltd.'),
    createData('GM00010',	'鉉軒工程有限公司'),
    createData('GM00011',	'鑑記金屬結構有限公司'),
    createData('GM00012',	'梁湛記建築有限公司'),
    createData('GM00013',	'竣煌有限公司'),
    createData('GM00014',	'新立工程香港有限公司'),
    createData('GM00015',	'中華行(香港)工程有限公司'),
    createData('GM00016',	'皓星工程有限公司'),
    createData('GM00017',	'佶鑫（亞太）建材有限公司'),
    createData('GM00018',	'新麗工程(亞洲)有限公司'),
    createData('GM00019',	'華騰鋼鐵工程有限公司'),
    createData('GM00020',	'新天工程有限公司'),
    createData('GM00021',	'新鷹預製件有限公司'),
    createData('GM00022',	'有利承造裝修有限公司'),
    createData('GM00023',	'陳棠記土木工程有限公司'),
    createData('GM00024',	'利亨土木工程有限公司'),
    createData('GM00025',	'安成工程有限公司'),
    createData('GM00026',	'寶發建設工程有限公司'),
    createData('GM00027',	'大龍國際有限公司'),
    createData('GM00028',	'易合企業有限公司'),
    createData('GM00029',	'聯邦鋼鐵工程有限公司'),
    createData('GM00030',	'協盛幕牆工程有限公司'),
    createData('GM00031',	'東昇結構工程有限公司'),
    createData('GM00032',	'力豐營造有限公司'),
    createData('GM00033',	'金磊工程有限公司'),
    createData('GM00034',	'金旭鋼鐵工程有限公司'),
    createData('GM00035',	'金源工程有限公司'),
    createData('GM00036',	'鉅城工程有限公司'),
    createData('GM00037',	'財記工程(香港)有限公司'),
    createData('GM00038',	'香港興業工程有限公司'),
    createData('GM00039',	'東翹有限公司'),
    createData('GM00040',	'力堡工程(香港)有限公司'),
    createData('GM00041',	'聯藝工程國際有限公司'),
    createData('GM00042',	'澳港建設(香港)有限公司'),
    createData('GM00043',	'順達工程發展(香港)有限公司'),
    createData('GM00044',	'鐵木新工程有限公司'),
    createData('GM00045',	'建明建築工程有限公司'),
    createData('GM00046',	'億源建材有限公司'),
    createData('GM00047',	'得力營造有限公司'),
    createData('GM00048',	'力新工程有限公司'),
    createData('GM00049',	'北海工程(香港)有限公司'),
    createData('GM00050',	'風順鋼鐵工程有限公司'),
    createData('GM00051',	'恒豐鐵器工程有限公司'),
    createData('GM00052',	'宏志建業公司'),
    createData('GM00053',	'盛世建築工程有限公司'),
    createData('GM00054',	'創駿工程有限公司'),
    createData('GM00055',	'有信建築有限公司'),
    createData('GM00056',	'協興隆舞台工程有限公司'),

  ];

  const rows2 = [
    createData('AM00001',	'泰興祥卡板公司'),
    createData('AM00002',	'喜利得(香港)有限公司'),
    createData('AM00003',	'匯賢建築材料國際有限公司'),
    createData('AM00004',	'佰德有限公司'),
    createData('AM00005',	'鈞順（香港）建築材料有限公司'),
    createData('AM00006',	'世昌鋁業(中國)有限公司'),
    createData('AM00007',	'港進建機服務中心有限公司')
  ];

  const rows3 = [
    createData('GMO00001',	'廣東唯楚建材有限公司'),
    createData('GMO00002',	'佛山市順德貝克達金屬制品有限公司'),
    createData('GMO00003',	'佛山市南海桂城漢宏金屬制品廠'),
    createData('GMO00004',	'廣東高麗鋁業有限公司'),
    createData('GMO00005',	'廣東力得金屬材料有限公司'),
    createData('GMO00006',	'廣東海控特種玻璃技術有限公司'),
  ];

function MemberListPage(props) {
    const { t, i18n } = useTranslation();

    return (
        <RootBox sx={{ minHeight: 'calc(100vh - 210px)' }}>
            <Root className={classes.root}>
                <Box sx={{ mt: { xs: 8.25, md: 12.5 } }}>
                    <Breadcrumb breadcrumbData={breadcrumbData} />
                </Box>
                {/* Noto Sans HK */}
                <Box sx={{ my: 2.5 }}>
                    <Typography variant="h4">{"會員名冊"}</Typography>
                </Box>
                <Box sx={{ my: 2 }}>
                    <Box sx={{ my: 1 }}>
                        <Typography variant="h6">
                            {'一般會員'}
                        </Typography>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table aria-label="member table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>編號</StyledTableCell>
                                    <StyledTableCell align="left">公司名稱</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows.map((row) => (
                                <StyledTableRow key={row.memberId}>
                                <StyledTableCell component="th" scope="row">
                                    {row.memberId}
                                </StyledTableCell>
                                <StyledTableCell align="left">{row.companyName}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ mt:2, mb: 1 }}>
                        <Typography variant="h6">
                            {'協同會員'}
                        </Typography>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table aria-label="member table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>編號</StyledTableCell>
                                    <StyledTableCell align="left">公司名稱</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows2.map((row) => (
                                <StyledTableRow key={row.memberId}>
                                <StyledTableCell component="th" scope="row">
                                    {row.memberId}
                                </StyledTableCell>
                                <StyledTableCell align="left">{row.companyName}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ mt: 2, mb: 1 }}>
                        <Typography variant="h6">
                            {'一般會員（海外）'}
                        </Typography>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table aria-label="member table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>編號</StyledTableCell>
                                    <StyledTableCell align="left">公司名稱</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows3.map((row) => (
                                <StyledTableRow key={row.memberId}>
                                <StyledTableCell component="th" scope="row">
                                    {row.memberId}
                                </StyledTableCell>
                                <StyledTableCell align="left">{row.companyName}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Root>
        </RootBox>
    )
}

const PREFIX = "member-list";

const classes = {
    root: `${PREFIX}-root`,
    loading: `${PREFIX}-loading`,
};


const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        height: '100%',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
    [`&.${classes.loading}`]: {
        height: '100vh',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? '#1A2027'
        : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('img/bg.png')`,
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

const Title = (props) => {
    return (
        <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    spacing={0}
                    direction="row"
                    justifyContent={props.position ? props.position : "center"}
                >
                    <Typography
                        variant="h4"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {props.title}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const SubTitle = (props) => {
    return (
        <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    spacing={0}
                    direction="row"
                    justifyContent={props.subtitle ? props.subtitle : "center"}
                >
                    <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {props.subtitle}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const CustomDivider = styled(Divider)(({ theme }) => ({
    [`&.about`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : '#7A5D26',
        borderBottomWidth: 3,
        width: "100%"
        // marginBottom: 10
    },
    [`&.companyBusiness`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : '#7A5D26',
        borderBottomWidth: 2,
        width: "5%"
        // marginBottom: 10
    },

}));

const ScaleCard = styled(Box)(({ theme }) => ({
    "&:hover": {
        transition: "transform 0.5s ease-in-out",
        transform: "scale3d(1.05, 1.05, 1)"
    },
}));

export default MemberListPage;